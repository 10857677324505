









































import {vxm} from "@/store";
import {Component, Vue, Prop} from 'vue-property-decorator';
import {ReminderDTO} from "@shared_vue/types/ReminderDTO";
import {ErpcDTO} from "@shared_vue/openapi/caseapi/api";


@Component
export default class BranchRow extends Vue {

  @Prop() readonly branch: ErpcDTO | undefined
  @Prop() readonly clickFunc1: Function | undefined
  @Prop() readonly clickFunc2: Function | undefined
  private ui = vxm.ui;
  private imagePath = process.env.VUE_APP_STATIC_DIR + 'images/icons/';
  private getImage(logo: any) {
    if (logo) {
      return this.imagePath + logo
    }
  };

}
