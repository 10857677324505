<template>
  <CModal :show.sync="show">
    <template #header>
      <h6 class="modal-title">Add Division</h6>
    </template>
    <CInput
        :horizontal="true"
        v-model="divisionName"
        placeholder="Enter Name"
        label="Name"
        name="name"
    ></CInput>
    
    <template #footer>
      <CButton @click="closeModal" color="primary">Cancel</CButton>
      <CButton @click="emitDivision" color="success">OK</CButton>
    </template>
  </CModal>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  name: 'DivisionAddModal',
  components: {
    
  },
  data() {
    return {
      divisionName: '',
    };
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    
    emitDivision() {
      this.$emit('newDivision', this.divisionName);
      this.closeModal(); // If you have a method to close the modal
    },
    closeModal() {
      this.$emit('update:show', false);
    }
  }
};
</script>
