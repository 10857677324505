






















































import {vxm} from "@/store";
import {Component, Prop, Vue} from 'vue-property-decorator';
import {CaseApiFactory, CompanyDTO, Configuration, ErpcDTO, SectorDTO,} from "@shared_vue/openapi/caseapi";

import ErpcSection from "@/views/widgets/ErpcSection.vue"
import CalendarModal from "@/views/widgets/modals/CalendarModal.vue";
import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import ErrorModal from "@/views/widgets/modals/ErrorModal.vue";
import MiniMap from "@/views/viewcomponents/MiniMap.vue";
import AddAddressModal from "@shared_vue/components/AddAddressModal.vue";
import BranchRow from "@/views/viewcomponents/BranchRow.vue";
import SectorAddModal from "@/views/widgets/modals/SectorAddModal.vue";
import DivisionAddModal from "@/views/widgets/modals/DivisionAddModal.vue";

@Component({
  components: { ErpcSection, WaitModal, ErrorModal, MiniMap, AddAddressModal, CalendarModal, BranchRow, SectorAddModal, DivisionAddModal },
})
export default class ErpcDetail extends Vue {
  private auth = vxm.auth;
  @Prop(String) readonly companyid: string | undefined;
  
  private showAddSectorModal: boolean = false;
  private showDivisionAddModal: boolean = false;
  private clickedSector: string = '';
  
  get companyId () {
    return this.companyid ? parseInt(this.companyid) : undefined;
  }
  
  private startAddDivision(sectorName: string){
    this.showDivisionAddModal=true;
    this.clickedSector = sectorName;
  }
  
  private async addDivision2(divisionName: string){
    console.log(`adding division ${divisionName}`);
    this.showDivisionAddModal=false;
    await this.addDivision(divisionName);
  }
  
  private async addDivision(divisionName: string){
    this.showWait = true;
    try {
      //add division
      const newDivision = {
        branchName: divisionName,
        companyId: this.companyId!,
        stateId: 'A',
        sectorId: this.clickedSector
      }
      //@ts-ignore
      const result = await this.caseApi.caseUpsertBranch(newDivision)
      //navigate to division page
      this.$router.push(`/dashboard/erpc/${result.data.id}`);
    } finally {
      this.showWait = false;
    }
  }
  
  private async saveSector(sectorName: string){
    console.log(`saving sector ${sectorName}`);
    this.showWait = true;
    try {
      await this.caseApi.caseUpsertSector({name: sectorName, id: sectorName, companyId: this.companyId!})
      await this.fetchData(this.companyId!);
    } finally {
      this.showWait = false;
    }
  }
  
  private thisCompany: CompanyDTO = <CompanyDTO>{};
  private caseApi = CaseApiFactory(<Configuration>{ basePath: process.env.VUE_APP_CASE_API_URL }, process.env.VUE_APP_CASE_API_URL)
  private waitABit = false;
  private showCal = false;
  private caseStore = vxm.case;
  private showWait: boolean = false;
  private showError = false;
  private branchList: ErpcDTO[] = [];
  private sectorList: SectorDTO[] = []
  private groupedBranches: [string, ErpcDTO[]][] = [];
  private showSuccessModal: boolean = false;
  private titleString = 'Loading...';

  private cancel(){
    this.showSuccessModal=false;
  }
  
  get modalTitle(){
    return this.titleString;
  }
  private filterAndGroupByCompanyAndSector(erpcs: ErpcDTO[], sectors: SectorDTO[]) {
    //iterate through the sectors and assign all erpc's belonging to the sector to it
    let groupedBySector: [string, ErpcDTO[]][] = [];
    sectors.forEach((sector) => {
      let erpcsForSector = erpcs.filter((erpc) => erpc.sectorId === sector.id);
      groupedBySector.push([sector.name, erpcsForSector]);
    });
    //sort in ascending sector name order
    groupedBySector.sort((a, b) => a[0].localeCompare(b[0]));
    return groupedBySector;
  }
  
  private addSector(){
    this.showAddSectorModal=true;
  }
  
  private goBack(){
    this.$router.go(-1);
  }
  
  
  private async fetchData(id: number) {
    if (!this.auth.idToken) {
      this.waitABit = true;
      return;
    }
    
    let response = await this.caseApi.caseGetCompany(id);
    
    this.thisCompany = response.data;
    let result2 = await this.caseApi.caseGetBranchesForCompany(id);
    this.branchList = result2.data;
    
    let result3 = await this.caseApi.caseGetSectorsForCompany(id);
    this.sectorList = result3.data;
    
    this.groupedBranches = this.filterAndGroupByCompanyAndSector(this.branchList, this.sectorList);
    
  }
  
  mounted() {

    

    if (this.companyId ) { //note we must not just fetch CaseDTO there is more info than that here
      console.log(`fetching data for company ${this.companyId}`)
      try { 
        this.fetchData(this.companyId!)
      } catch (e) {
        console.log(e)
      } finally {
        this.waitABit = false;
      }
    }
    

  }
  
  private showProfile(branchId: number){
    this.$router.push(`/dashboard/erpc/${branchId}`);
  }
  
  private showMembers(branchId: number){
    this.$router.push(`/dashboard/erpcmembers/${branchId}`);
    
  }
}
