


















import {Component, Prop, PropSync, Vue} from 'vue-property-decorator';

@Component
export default class ErpcSection extends Vue {
  @Prop(String) readonly title: string | undefined;
  private expanded: boolean = false;
  private addDivision() {
    this.$emit('addDivision');
  }
}
